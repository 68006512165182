import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import SearchBox from "./SearchBox";
import { targetUrl } from "../config.js"

const Header = () => {
    const location = useLocation();
    const isMainPage = location.pathname === '/';

    return (
        <>
            <div className={`header ${isMainPage ? 'main' : ''}`}>
                {/*<div className="visual">
                    <img src={process.env.PUBLIC_URL + '/img/logo/logo_img_wide.png'} alt="경매가 어렵다는 생각은 이제 더 이상 안하셔도 좋습니다!"/>
                </div>*/}
                <h1 className="brand"><Link className="logo" to='/'><img src={process.env.PUBLIC_URL + '/img/logo/logo_img_color.png'} style={{height:"8%", width:"280%"}} alt="나비잠 안마의자"/></Link></h1> {/*logo_temp_v2.png*/}
                {/*<SearchBox />*/}
                {/*<div className="pc-menu">
                    <Link className="btn-top btn-go-notification on" to='/monitoring'><i className="ico ico-notification"/></Link>
                    <Link className="btn-top btn-go-shop" to='/shop'><i className="ico ico-shop"/></Link>
                    <Link className="btn-go-login" to={localStorage.getItem('id')? '/Mypage':'/login'}><i className="ico ico-login"/><span className="txt">{localStorage.getItem('id')? localStorage.getItem('id'):"로그인해주세요"}</span></Link>
                </div>*/}
            </div>
        </>
    );
};

export default Header;
