import React, { useState, useCallback, useEffect } from 'react';
import Header from '../Components/Header';
import Modal from 'react-bootstrap/Modal';
import MobileDetailMenu from "../Components/MobileDetailMenu";
import {Link} from "react-router-dom";
import { targetUrl } from "../config.js"
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RoomName, RankList, RankPrice, roomImgDict, RoomRank, RankColor, RoomExplain } from "./config.js"
import { useLocation } from 'react-router-dom';
import { MdErrorOutline } from "react-icons/md";


const FailPay = () => {

  const location = useLocation();
  console.log(location)
  const [data, setData] = useState(null);

  const deleteRoom = (event) => {
    fetch(process.env.REACT_APP_serviceUrl + "/reservation"//stgUrl + "/reservation"
    , {
        credentials: 'include',
        method: "DELETE",
        headers: {
              "Content-Type": "application/json",
        },
        }).then(res => res.json())
        .then(json => {
            console.log(json)
            if (json.status=="success") {
                console.log("예약내역 삭제 성공")
            } else {
                console.log("예약내역 삭제 실패")
            }

        })
    }

  useEffect(() => {
    setData(location.state)
    deleteRoom()
  }, []);
    return (
        <>
            <div className="mypage-wrap">
                <div className="inner">
                    <div className="complete">
                        <MdErrorOutline color="red" size={70} />
                        <div className="space">
                            오류가 발생하여 결제가 완료되지 못했습니다. 다시 시도해주세요.
                        </div>
                    </ div>
                </div>
            </div>
        </>
    );
};

export default FailPay;

