import React, { useState, useCallback, useEffect } from 'react';
import Header from '../Components/Header';
import Modal from 'react-bootstrap/Modal';
import MobileDetailMenu from "../Components/MobileDetailMenu";
import {Link} from "react-router-dom";
import { addComma } from "./config.js"
import { FaRegCheckCircle } from "react-icons/fa";
import { BrowserView, MobileView } from 'react-device-detect'
import { RoomName, RankList, RankPrice, roomImgDict, RoomRank, RankColor, RoomExplain, BankCode } from "./config.js"
import Select from 'react-select'
import { chairExplain } from "./data.js"

const CompleteNiceAPI = () => {
    console.log(window.location.href)
    const [step, setStep] = useState(0);
    const [q1, setQ1] = useState(null);
    const [q2, setQ2] = useState(null);
    const [q3, setQ3] = useState(null);
    const [q4, setQ4] = useState([]);
    const [q5, setQ5] = useState(null);
    const [q6, setQ6] = useState(null);
    const [rankList, setRankList] = useState()
    const nextStep = (event) => {
        if (step==0) {
            if (q1==null) {
                window.alert("질문에 대한 답을 선택해주세요.")
            }else {
                setStep(prev=>prev+1)
            }
        } else if (step==1) {
            if (q2==null) {
                window.alert("질문에 대한 답을 선택해주세요.")
            }else {
                setStep(prev=>prev+1)
            }
        } else if (step==2) {
            if (q3==null || q4==null || q5==null || q6==null) {
                window.alert("질문에 대한 답을 모두 선택해주세요.")
            }else {
                setStep(prev=>prev+1)
            }
        }
    }
    const nextStepFinal = (event) => {
        if (step==2) {
            if (q3==null || q4==null || q5==null || q6==null) {
                window.alert("질문에 대한 답을 모두 선택해주세요.")
            }else {
                calcRank()

            }
        }
    }

    function calcRank() {
       let rankDict = {}
       for (var key=0; key<Object.keys(chairExplain).length; key++) {
            const chair = Object.keys(chairExplain)[key]
            const score1 = chairExplain[chair]["score"][q1]
            const score2 = chairExplain[chair]["score"][q2]
            const score3 = chairExplain[chair]["score"][q3]
            let score4 = 0
            for (var i; i<q4.length; i++) {
                score4 += i
            }
            const score5 = chairExplain[chair]["score"][q5]
            const score6 = chairExplain[chair]["score"][q6]
            console.log(chairExplain[chair]["score"])
            console.log(q1)
            console.log(score1)
            console.log(q2)
            console.log(score2)
            console.log(q3)
            console.log(score3)
            console.log(q4)
            console.log(score4)
            console.log(q5)
            console.log(score5)
            console.log(q6)
            console.log(score6)
            rankDict[chair] = score1*(score2+score3+score4+score5+score6)

       }
       var rankList = Object.keys(rankDict).map(function(key) {
          return [key, rankDict[key]];
       });
       rankList.sort(function(first, second) {
          return second[1] - first[1];
       });
       console.log(rankList)
       setRankList(rankList)
       setStep(prev=>prev+1)
    }

    const prevStep = (event) => {
        if (step>0) {
            setStep(prev=>prev-1)
        }
    }
    const updateQ4 = (event) => {
        if (q4.includes(event.target.id)){
            setQ4(q4.filter(elem => elem!=event.target.id))
        }
        else{
            setQ4(prev => [...prev, event.target.id])
        }
    }

    useEffect(() => {

    },[])

    return (
        <>
            <div className="mypage-wrap">
                <div className="inner">
                     <BrowserView>
                        <div className={"line-rec up on"} id="mobile" > 안마의자 추천받기 </div>
                    </BrowserView>

                    <MobileView>
                        <div className={"line-rec-mobile up on"} id="mobile" > 안마의자 추천받기 </div>
                    </MobileView>
                    <hr className="line" />

                    <BrowserView>
                        <div className={step>=0? "rectangle left on":"rectangle left"} id="1"> STEP 1 : 지점선택 </div>
                        <div className={step>=1? "rectangle on":"rectangle"} id="2"> STEP 2 : 방문목적 </div>
                        <div className={step>=2? "rectangle on":"rectangle"} id="3"> STEP 3 : 상세질문 </div>
                        <div className={step>=3? "rectangle right on":"rectangle right"} id="4"> STEP 4 : 결과확인 </div>
                    </BrowserView>
                    <MobileView>
                        <div className={step>=0? "rectangle mobile left on":"rectangle mobile left"} id="1"> 1.지점선 </div>
                        <div className={step>=1? "rectangle mobile on":"rectangle mobile"} id="2"> 2.방문목적 </div>
                        <div className={step>=2? "rectangle mobile on":"rectangle mobile"} id="3"> 3.상세질문 </div>
                        <div className={step>=3? "rectangle mobile right on":"rectangle mobile right"} id="4"> 4.결과확인 </div>
                    </MobileView>
                    {step==0?
                    <div className="form-box">
                        <div className="date left">
                            {"이용하실 지점을 선택해주세요."}
                        </div>
                        <div className={q1=="Gwanghwamun"?"rec-selectbox on":"rec-selectbox"} id="1" onClick={()=>setQ1("Gwanghwamun")}>
                            {"광화문점"}
                        </div>
                        <div className={q1=="Yeouido"?"rec-selectbox on":"rec-selectbox"} id="2" onClick={()=>setQ1("Yeouido")}>
                            {"여의도점"}
                        </div>

                        <div className="btn-wrap">
                            <button className="btn btn-black" onClick={nextStep}>다음으로</button>
                        </div>
                    </div>
                    :
                    step==1?
                    <div className="form-box">
                        <div className="date left">
                            {"이용 목적을 선택해주세요."}
                        </div>
                        <div className={q2=="Rest"?"rec-selectbox on":"rec-selectbox"} id="1" onClick={()=>setQ2("Rest")}>
                            {"수면 및 휴식"}
                        </div>
                        <div className={q2=="Massage"?"rec-selectbox on":"rec-selectbox"} id="2" onClick={()=>setQ2("Massage")}>
                            {"시원한 마사지"}
                        </div>
                        <div className="btn-wrap">
                            <button className="btn btn-black" onClick={nextStep}>다음으로</button>
                        </div>
                        <div className="txt-underline" onClick={prevStep}>
                            이전단계로
                        </div>
                    </div>
                    :
                    step==2?

                    <div className="form-box">
                        <div className="date left">
                            {"고객님의 신장(키)를 선택해주세요."}
                        </div>
                        <div className={q3=="Tall"?"rec-selectbox on":"rec-selectbox"} id="1" onClick={()=>setQ3("Tall")}>
                            {"175cm 미만"}
                        </div>
                        <div className={q3=="Small"?"rec-selectbox on":"rec-selectbox"} id="2" onClick={()=>setQ3("Small")}>
                            {"175cm 이상"}
                        </div>
                        <div className="date left">
                            {"중점적으로 마사지받고 싶은 부위를 선택해주세요. (복수 선택가능)"}
                        </div>
                        <div className={q4.includes("Neck")?"rec-selectbox mini on":"rec-selectbox mini"} id="Neck" onClick={updateQ4}>
                            {"목/어깨"}
                        </div>
                        <div className={q4.includes("Back")?"rec-selectbox mini on":"rec-selectbox mini"} id="Back" onClick={updateQ4}>
                            {"등/허리"}
                        </div>
                        <div className={q4.includes("Hip")?"rec-selectbox mini on":"rec-selectbox mini"} id="Hip" onClick={updateQ4}>
                            {"엉덩이/골반"}
                        </div>
                        <div className={q4.includes("Thigh")?"rec-selectbox mini on":"rec-selectbox mini"} id="Thigh" onClick={updateQ4}>
                            {"허벅지"}
                        </div>
                        <div className={q4.includes("Calf")?"rec-selectbox mini on":"rec-selectbox mini"} id="Calf" onClick={updateQ4}>
                            {"종아리"}
                        </div>
                        <div className={q4.includes("Foot")?"rec-selectbox mini on":"rec-selectbox mini"} id="Foot" onClick={updateQ4}>
                            {"발바닥"}
                        </div>
                        <div className="date left">
                            {"선호하는 마사지 스타일을 선택해주세요."}
                        </div>
                        <div className={q5=="Thai"?"rec-selectbox on":"rec-selectbox"} id="1" onClick={()=>setQ5("Thai")}>
                            {"압이 쎄고 자극이 시원한 타이마사지"}
                        </div>
                        <div className={q5=="Vietnam"?"rec-selectbox on":"rec-selectbox"} id="2" onClick={()=>setQ5("Vietnam")}>
                            {"섬세하고 부드럽게 롤링해주는 베트남 마사지"}
                        </div>
                        <div className="date left">
                            {"선호하는 마사지 세기를 선택해주세요."}
                        </div>
                        <div className={q6=="1"?"rec-selectbox mini on":"rec-selectbox mini"} id="1" onClick={()=>setQ6("1")}>
                            {"약하게"}
                        </div>
                        <div className={q6=="2"?"rec-selectbox mini on":"rec-selectbox mini"} id="2" onClick={()=>setQ6("2")}>
                            {"중간"}
                        </div>
                        <div className={q6=="3"?"rec-selectbox mini on":"rec-selectbox mini"} id="3" onClick={()=>setQ6("3")}>
                            {"세게"}
                        </div>
                        <div className="btn-wrap">
                            <button className="btn btn-black" onClick={nextStepFinal}>다음으로</button>
                        </div>
                        <div className="txt-underline" onClick={prevStep}>
                            이전단계로
                        </div>
                    </div>
                    :
                    step==3?
                    <div className="form-box">
                        <div className="date left">
                            {"고객님에게 맞는 안마의자 결과입니다."}
                        </div>
                        <div className="date left">
                            {"1. "+rankList[0][0]}
                        </div>
                        <div className="date left">
                            {"2. "+rankList[1][0]}
                        </div>
                        <div className="date left">
                            {"3. "+rankList[2][0]}
                        </div>
                        <div className="btn-wrap">
                            <button className="btn btn-black" onClick={()=>window.location.href="/book"}>예약하기</button>
                        </div>
                        {/*<div className={q2=="Rest"?"rec-selectbox on":"rec-selectbox"} id="1" >
                            {"수면 및 휴식"}
                        </div>
                        <div className={q2=="Massage"?"rec-selectbox on":"rec-selectbox"} id="2">
                            {"시원한 마사지"}
                        </div>
                        <div className="btn-wrap">
                            <button className="btn btn-black" onClick={nextStep}>다음으로</button>
                        </div>
                        <div className="txt-underline" onClick={prevStep}>
                            이전단계로
                        </div>*/}
                    </div>
                    :
                    <>
                    </>
                    }
                </div>
            </div>
            {/*<MobileDetailMenu />
            <Modal className="modal-green" show={show} onHide={handleClose} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleClose}><i className="ico ico-modal-close-white" /></button>
                <div className="content">
                    <div className="title">회원 가입을 환영 합니다!</div>
                    <div className="desc">회원 가입 완료</div>
                    <div className="sign-in-completed">
                        <i className="ico ico-completed"/>
                        <div className="txt">{parsed==null? parsed: ''}</div>
                    </div>
                    <div className="btn-wrap">
                        <Link className="link" to='/'><button className="btn btn-black btn-lg" onClick={handleClose}>확인</button></Link>
                    </div>
                </div>
            </Modal>*/}
        </>
    );
};

export default CompleteNiceAPI;
