import React, { useState, useEffect, useRef } from 'react';
import Header from '../Components/Header';
import Modal from 'react-bootstrap/Modal';
import {Link} from "react-router-dom";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { IoInformationCircleOutline } from "react-icons/io5";
import { RoomName, RankList, RankPrice, roomImgDict, RoomRank, RankColor, RoomExplain, branchList, branchDict, rebranchDict, roomList, numList, saleDict } from "./config.js"
import { TbStar, TbStarHalf } from "react-icons/tb";
import { BrowserView, MobileView } from 'react-device-detect'
import * as moment from 'moment'
import { useNavigate } from 'react-router-dom';

import {
  PaymentWidgetInstance,
  loadPaymentWidget,
  ANONYMOUS,
} from "@tosspayments/payment-widget-sdk"
import { nanoid } from "nanoid";

const Booking = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [branch, setBranch] = useState("Gwanghwamun");
    const [branchSelect, setBranchSelect] = useState(false);

    return (
        <>
            <div className="mypage-wrap">
                <div className="inner">
                    <BrowserView>
                        <div className={step>=0? "rectangle left on":"rectangle left"} id="1"> STEP 1 : 지점 선택 </div>
                        <div className={step>=1? "rectangle on":"rectangle"} id="2"> STEP 2 : 방 선택 </div>
                        <div className={step>=2? "rectangle on":"rectangle"} id="3"> STEP 3 : 예약 정보 </div>
                        <div className={step>=3? "rectangle on":"rectangle"} id="4"> STEP 4 : 요금 결제 </div>
                        <div className={step>=4? "rectangle right on":"rectangle right"} id="5"> STEP 5 : 예약완료 </div>
                    </BrowserView>
                    <MobileView>
                        <div className={step>=0? "rectangle left on":"rectangle left"} id="1"> 1. 지점선택 </div>
                        <div className={step>=1? "rectangle on":"rectangle"} id="2"> 2. 방선택 </div>
                        <div className={step>=2? "rectangle on":"rectangle"} id="3"> 3. 정보 </div>
                        <div className={step>=3? "rectangle on":"rectangle"} id="4"> 4. 결제 </div>
                        <div className={step>=4? "rectangle right on":"rectangle right"} id="5"> 5. 완료 </div>
                    </MobileView>
                    {/*<hr/>*/}
                    <div className="form-box">
                        <div className="date left">
                            {"이용하실 지점을 선택해주세요."}
                        </div>
                        <div className={branchSelect==true && branch=="Gwanghwamun"?"rec-selectbox on":"rec-selectbox"} id="1" onClick={()=>{sessionStorage.setItem('branch', "Gwanghwamun"); setBranch("Gwanghwamun");setBranchSelect(true)}}>
                            {"광화문점"}
                        </div>
                        <div className={branchSelect==true && branch=="Yeouido"?"rec-selectbox on":"rec-selectbox"} id="2" onClick={()=>{sessionStorage.setItem('branch', "Yeouido"); setBranch("Yeouido");setBranchSelect(true)}}>
                            {"여의도점"}
                        </div>

                        <div className="btn-wrap">
                            <button className="btn btn-black" onClick={()=>{if(branchSelect==false){window.alert("지점을 선택해주세요.")} else{navigate( '/room', { state: { branch:branch } } )}}}>다음으로</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Booking;


