import React, { useState, useCallback, useEffect } from 'react';
import Header from '../Components/Header';
import Modal from 'react-bootstrap/Modal';
import MobileDetailMenu from "../Components/MobileDetailMenu";
import {Link} from "react-router-dom";
import { addComma } from "./config.js"
import { FaRegCheckCircle } from "react-icons/fa";
import { BrowserView, MobileView } from 'react-device-detect'
import { RoomName, RankList, RankPrice, roomImgDict, RoomRank, RankColor, RoomExplain, BankCode, rebranchDict } from "./config.js"
import Select from 'react-select'

const CompleteNiceAPI = () => {
    console.log(window.location.href)
    const [parsed, setParsed] = useState(null);
    const [show, setShow] = useState(true);
    const [token, setToken] = useState(null);
    const [tab, setTab] = useState("email");
    const [open, setOpen] = useState(false);
    const [enc, setEnc] = useState(null);
    const [integrity, setIntegrity] = useState(null);
    const [time, setTime] = useState(-100)
    const [id, setID] = useState("")
    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [history, setHistory] = useState([])
    const [getData, setGetData] = useState(false)
    const [refund, setRefund] = useState(false)
    const [expireTime, setExpireTime] = useState(new Date())
    const [bankCode, setBankCode] = useState("")
    const [accNum, setAccNum] = useState("")
    useEffect(() => {
        const initData = sessionStorage.getItem("BookHistory")
        console.log("initData")
        console.log(initData)
        if (initData!=null && initData!="null"){
            setHistory(JSON.parse(initData))
            setGetData(true)
            console.log(new Date())
            console.log(new Date(JSON.parse(initData)[0].startDateTime))
            console.log(new Date(Date.parse(JSON.parse(initData)[0].startDateTime)))
            let gap = new Date(Date.parse(JSON.parse(initData)[0].startDateTime))-new Date()
            console.log(new Date(Date.parse(JSON.parse(initData)[0].startDateTime))-new Date())
            console.log(gap/1000)
            console.log(gap/(1000*60))
            console.log(gap/(60*1000))
            console.log(Date.parse(JSON.parse(initData)[0].startDateTime)-new Date())
            console.log((Date.parse(JSON.parse(initData)[0].startDateTime)-new Date())/60)
            console.log((Date.parse(JSON.parse(initData)[0].startDateTime)-new Date())/(60*60))
            console.log((Date.parse(JSON.parse(initData)[0].startDateTime)-new Date())/(60*60*60))
        }
        {/*else {
            if (window.location.href.slice(-4,)=="book"){
                regetList()
            }
        }*/}

    },[])

    useEffect(() => {
        if (time > 0) {
          const Counter = setInterval(() => {
            const gap = Math.floor((expireTime.getTime() - new Date().getTime()) / 1000)
            setTime(gap)
            console.log("gap:",gap)
          }, 1000)
          return () => clearInterval(Counter)
        } else if (time==0) {
            window.alert("인증시간이 만료되었습니다.")
            setOpen(false)
        }
      }, [expireTime, time])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleTab = (e) => {
        setTab(e.target.id)
        setOpen(false)
        setID("")
    }


    const handleOpen = (e) => {
        if (id==null){
            window.alert("휴대폰번호 또는 이메일을 입력해주세요.")
        } else {
            fetch(process.env.REACT_APP_serviceUrl + "/account/code", {
                credentials: 'include',
                method: "POST",
                headers: {
                      "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                    "type": tab,
                    "id": id
                    }
                )
            }).then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.status=="success") {
                    const nowDate = new Date()
                    nowDate.setSeconds(nowDate.getSeconds() + 180)
                    setExpireTime(nowDate)
                    setTime(179)
                    setCode("")
                    setOpen(true)
                } else {
                    window.alert(json.message)
                }

            })
        }
    }

    const resetList = () => {
        setGetData(false)
        setHistory([])
        sessionStorage.removeItem("BookHistory")
        setOpen(false)
        setRefund(false)
        setName("")
        setID("")
        setCode("")
        setBankCode("")
        setAccNum("")
    }
    const regetList = () => {
        fetch(process.env.REACT_APP_serviceUrl + `/my-reservations`, {
            credentials: 'include',
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            }).then(res => res.json())
            .then(json => {
                if (json.status=="success"){
                    console.log(json.data);
                    sessionStorage.setItem("BookHistory", JSON.stringify(json.data))
                    setHistory(json.data)
                } else {

                }

            })
    }
    const getRefund = (e) => {
        console.log({
                    "cancelReason": "단순변심 (30분이전 환불)",
                    "id": e.target.value,
                    "refundReceivAccountBank": bankCode,
                    "refundReceivAccountNumber": accNum,
                    "refundReceivAccountHolderName": name
                    })
        fetch(process.env.REACT_APP_serviceUrl + "/payment/cancel", {
                credentials: 'include',
                method: "POST",
                headers: {
                      "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                    "cancelReason": "단순변심 (30분이전 환불)",
                    "id": e.target.value,
                    "refundReceiveAccountBank": bankCode,
                    "refundReceiveAccountNumber": accNum,
                    "refundReceiveAccountHolderName": name
                    }
                )
            }).then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.status=="success") {
                    window.alert("환불이 완료되었습니다. ")
                    regetList()
                } else {
                    window.alert(json.message)
                }

            })
    }

    const getList = (event) => {
        fetch(process.env.REACT_APP_serviceUrl + "/account/login", {
                credentials: 'include',
                method: "POST",
                headers: {
                      "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                    "type": tab,
                    "id": id,
                    "code": code
                    }
                )
            }).then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.status=="success") {
                    sessionStorage.setItem("BookHistory", JSON.stringify(json.data))
                    setHistory(json.data)
                    setGetData(true)
                    setTime(-100)
                    setName("")
                    setID("")
                    setCode("")
                    window.alert("조회가 완료되었습니다.")
                } else {
                    window.alert("인증코드가 일치하지 않습니다.")
                }

            })
    }






    return (
        <>
            <div className="mypage-wrap">
                <div className="inner">
                    {getData?
                    <>
                        <BrowserView>
                            <div className={"line-rec up on"} id="result"> 예약내역 </div>
                        </BrowserView>

                        <MobileView>
                            <div className={"line-rec-mobile up on"} id="result"> 예약내역 </div>
                        </MobileView>
                    </>
                    :
                    <>
                        <BrowserView>
                            <div className={tab=="email"?"line-rec up on":"line-rec up"} id="email" onClick={handleTab}> 이메일로 조회 </div>
                            <div className={tab=="mobile"?"line-rec up on":"line-rec up"} id="mobile" onClick={handleTab}> 휴대폰번호로 조회 </div>
                        </BrowserView>

                        <MobileView>
                            <div className={tab=="email"?"line-rec-mobile up on":"line-rec-mobile up"} id="email" onClick={handleTab}> 이메일로 조회 </div>
                            <div className={tab=="mobile"?"line-rec-mobile up on":"line-rec-mobile up"} id="mobile" onClick={handleTab}> 휴대폰번호로 조회 </div>
                        </MobileView>
                    </>
                    }
                    <hr className="line" />
                    <div className="form-box">
                        {getData?
                        <div />
                        :
                        <>
                            <div className="form-group">
                                <label htmlFor="name" className="label">이름</label>
                                <input className="form-control" type="text" id="name" placeholder="성함을 입력해주세요." value={name} onChange={(e)=>{if(!open){setName(e.target.value)}}} />
                            </div>
                            {tab=="mobile"?
                            <div className="form-group">
                                <label htmlFor="tel2" className="label">전화번호</label>
                                <div className="form-with-btn">
                                    {/*<input className="form-control" type="text" id="tel2" placeholder="-를 제외한 숫자만 입력해주세요" value={id} onClick={(e)=>setID(e.target.value)}/>*/}
                                    <input className="form-control" type="text" id="tel" value={id.length>7? id.slice(0,3)+"-"+id.slice(3,7)+"-"+id.slice(7,) : id.length>3? id.slice(0,3)+"-"+id.slice(3,) : id}
                                onChange={(e)=>{if(!open){setID(e.target.value.toString().replaceAll("-",""))}}} placeholder="전화번호를 입력해 주세요." />
                                    <button className="btn-send" type="button" onClick={handleOpen}>인증번호 보내기</button>
                                </div>
                            </div>
                            :
                            <div className="form-group">
                                <label htmlFor="tel2" className="label">이메일</label>
                                <div className="form-with-btn">
                                    <input className="form-control" type="text" id="email" placeholder="이메일을 입력해주세요." value={id} onChange={(e)=>{if(!open){setID(e.target.value)}}}/>
                                    <button className="btn-send" type="button" onClick={handleOpen}>인증번호 보내기</button>
                                </div>
                            </div>
                            }
                            {open?
                            <div className="form-group">
                                <label htmlFor="number" className="label">인증번호</label>
                                <div className="form-with-btn">
                                    <input className="form-control" type="number" id="number" value={code} onChange={(e)=>setCode(e.target.value)} placeholder="인증번호를 입력해 주세요"/>
                                    <button className="btn-send colorBlack" type="button">{Math.max(Math.floor(time/60),0)+":"+((Math.max(time%60,0)).toString().length==1? '0'+Math.max(time%60,0).toString():Math.max(time%60,0))}</button>
                                </div>
                                <div className="btn-wrap">
                                    <button className="btn btn-black" onClick={getList}>조회하기</button>
                                </div>
                            </div>
                            :
                            <div/>
                            }
                        </>}
                        {/*{"\"김효정\"님께서 예약하신 내역입니다."}
                        <div className="btn-wrap">
                            <button className="btn btn-black" onClick={getList}>다시 조회하기</button>
                        </div>*/}
                        {getData?
                        <div className="txt-underline" onClick={resetList}>
                           다시 조회하기
                        </div>
                        :
                        <div/>
                        }
                        {history.map((item)=>
                        <div className={"history-list "+item.status}>
                            <div className={"rec-label "+item.status}>
                                {item.status=="DONE"? "결제완료" : (item.status=="CANCELED"?"결제취소":"가상계좌 입금전") }
                            </div>
                            <b>
                                {item.branch+" "}
                            </b>
                            <b>
                                {"- ROOM"+item.roomNo}
                            </b>
                            <a>
                                {" ("+RoomName[rebranchDict[item.branch]][item.roomNo]+")"}
                            </a>
                            <div className="text grey-mini">
                                {item.paymentDoneDateTime}
                            </div>
                            <hr />
                            <div className="text">
                                <b>예약시작시각 : </b>{item.startDateTime}
                            </div>
                            <div className="text">
                                <b>예약종료시각 : </b>{item.endDateTime}
                            </div>
                            <div className="text">
                                <b>{item.status=="DONE"? "결제완료금액" : (item.status=="CANCELED"?"결제취소금액":"가상계좌 입금 ("+item.virtualaccountDueDate+" 까지)") } : </b>
                                {item.status=="WAITING_FOR_DEPOSIT"? item.virtualaccountBankName+" "+item.virtualaccountAccountNumber+" ("+addComma(item.amount)+"원)"
                                :addComma(item.amount)+"원"}
                            </div>
                            {/*<div className="btn-wrap">*/}
                            {/* 일반적인 결제 완료후 */}
                            {item.status=="DONE" && (new Date(Date.parse(item.startDateTime))-new Date())/(1000*60)>30 && !refund && item.method!="가상계좌"?
                                <button className="btn btn-black" value={item.id} onClick={getRefund}>결제취소하기</button>
                                :
                                <div />
                            }
                            {/* 가상계좌 결제 완료 후 */}
                            {item.status=="DONE" && (new Date(Date.parse(item.startDateTime))-new Date())/(1000*60)>30 && !refund && item.method=="가상계좌"?
                            <button className="btn btn-black" onClick={()=>setRefund(true)}>결제취소하기</button>
                            :
                            <div />
                            }
                            {item.status=="DONE" && (new Date(Date.parse(item.startDateTime))-new Date())/(1000*60)>30 && refund && item.method=="가상계좌"?
                            <div>
                                <div className="form-group">
                                    <label htmlFor="name" className="label">이름</label>
                                    <input className="form-control" type="text" id="name" placeholder="성함을 입력해주세요." value={name} onChange={(e)=>{setName(e.target.value)}} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name" className="label">은행명</label>
                                    <Select options={BankCode} onChange={(e)=>{console.log(e); setBankCode(e.value)}}/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="id" className="label">계좌번호</label>
                                    {/*<input className="form-control" type="number" id="number" value={code} onChange={(e)=>setCode(e.target.value)} placeholder="계좌번호를 입력해 주세요"/>*/}
                                    <input className="form-control" type="number" id="acc" placeholder="계좌번호를 입력해주세요." value={accNum} onChange={(e)=>setAccNum(e.target.value)} />
                                    <button className="btn btn-black" value={item.id} onClick={getRefund}>결제취소하기</button>
                                </div>

                            </div>
                            :
                            <div></div>
                            }
                            {/* 가상계좌 입금전 */}
                            {item.status=="WAITING_FOR_DEPOSIT" && (new Date(Date.parse(item.paymentDoneDateTime))-new Date())/(1000*60)>0 && !refund?
                                <button className="btn btn-black" onClick={getRefund}>결제취소하기</button>
                                :
                                <div />
                            }
                            {/*</div>*/}
                        </div>)}

                    </div>
                </div>
            </div>
            {/*<MobileDetailMenu />
            <Modal className="modal-green" show={show} onHide={handleClose} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleClose}><i className="ico ico-modal-close-white" /></button>
                <div className="content">
                    <div className="title">회원 가입을 환영 합니다!</div>
                    <div className="desc">회원 가입 완료</div>
                    <div className="sign-in-completed">
                        <i className="ico ico-completed"/>
                        <div className="txt">{parsed==null? parsed: ''}</div>
                    </div>
                    <div className="btn-wrap">
                        <Link className="link" to='/'><button className="btn btn-black btn-lg" onClick={handleClose}>확인</button></Link>
                    </div>
                </div>
            </Modal>*/}
        </>
    );
};

export default CompleteNiceAPI;
