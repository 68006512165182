import React, {useState} from 'react';
import { Link, useLocation } from 'react-router-dom';
import ChatBot from 'react-simple-chatbot';
import { theme, steps } from "../Pages/config.js"
import { ThemeProvider } from 'styled-components';
import { TbMessageChatbot } from "react-icons/tb";


const MobileMenu = () => {
    const location = useLocation();
    const isMainPage = location.pathname === '/';
    const isSearchPage = location.pathname === '/result';

    const [isMenuVisible, setMenuVisible] = useState(false);

    const handleBtnFixedClick = () => {
        setMenuVisible(!isMenuVisible);
        document.body.classList.toggle('scrOff');
    };

    return (
        <>
            <div className={`mo-menu ${isMenuVisible ? 'show' : ''} ${isMainPage ? 'main' : ''} ${isSearchPage ? 'main' : ''}`}>
                <div className="menu">
                    <ThemeProvider theme={theme}>
                        <ChatBot
                          steps={steps}
                        />
                    </ThemeProvider>
                    {/*<div className="item"><a href="/"><img src={process.env.PUBLIC_URL + '/img/common/ico_shop.svg'} alt="shop"/><span>장바구니</span></a></div>*/}
                    {/*<div className="item"><a href="/login"><img src={process.env.PUBLIC_URL + '/img/common/ico_login.svg'} alt="login"/><span>로그인</span></a></div>
                    <div className="item"><a href="/add-listing"><img src={process.env.PUBLIC_URL + '/img/common/noti_ico.svg'} alt="login"/><span>매물 등록하기</span></a></div>
                    <div className="item"><a href="/"><img src={process.env.PUBLIC_URL + '/img/common/fav_list_ico.svg'} alt="favorite"/><span>관심 리스트</span></a></div>
                    <div className="item"><a href="/"><img src={process.env.PUBLIC_URL + '/img/common/mypage_ico.svg'} alt="mypage"/><span>마이 페이지</span></a></div>
                    <div className="item register"><a href="/"><img src={process.env.PUBLIC_URL + '/img/common/add_listing_ico.svg'} alt="register"/><span>매물 등록</span></a></div>*/}
                </div>
                <button className="btn-fixed" type="button" onClick={handleBtnFixedClick}>
                    <i className="ico ico-bottom-btn" />
                    {/*<TbMessageChatbot />*/}
                </button>
            </div>
            <div className="backdrop" />
        </>
    );
};

export default MobileMenu;
