import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import MobileMenu from "../Components/MobileMenu";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import {Link} from "react-router-dom";
import { targetUrl, number2hangeul } from "../config.js"
import Main from "../Pages/Main";
import Booking from "../Pages/Booking";
import Checking from "../Pages/Checking";
import QnAs from "../Pages/QnAs";

const Book = () => {
    const [category, setCategory] = useState("now");
    const [property, setProperty] = useState(null);
    const [message, setMessage] = useState(null);

    return (
        <>
            <Header/>
            <div className="main-wrap">
                <Tabs
                    defaultActiveKey="book"
                    id="main"
                    onSelect={(k) => window.location.href="/"+k}
                >
                    <Tab eventKey="" title="소개">
                        <Main/>
                    </Tab>
                    <Tab eventKey="book" title="예약/결제" >
                        <Booking/>
                    </Tab>
                    <Tab eventKey="check" title="조회">
                        <Checking/>
                    </Tab>
                    <Tab eventKey="qna" title="문의">
                        <QnAs />
                    </Tab>

                </Tabs>
            </div>
            <MobileMenu />
        </>
    );
};

export default Book;
