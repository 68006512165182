import React, { useState, useCallback, useEffect } from 'react';
import Header from '../Components/Header';
import Modal from 'react-bootstrap/Modal';
import MobileDetailMenu from "../Components/MobileDetailMenu";
import {Link} from "react-router-dom";
import { FaRegGrinSquint } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";

const ProcessPay = () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // 쿼리 파라미터 값이 결제 요청할 때 보낸 데이터와 동일한지 반드시 확인하세요.
    // 클라이언트에서 결제 금액을 조작하는 행위를 방지할 수 있습니다.
    const requestData = {
      orderId: searchParams.get("orderId"),
      amount: searchParams.get("amount"),
      paymentKey: searchParams.get("paymentKey"),
    };
    console.log(requestData)

    async function confirm() {
      const response = await fetch(process.env.REACT_APP_serviceUrl + "/payment/confirm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const json = await response.json();

      if (!response.ok) {
        // 결제 실패 비즈니스 로직을 구현하세요.
        navigate('/fail',
            { state: json.message } );
        return;
      }
        navigate( '/complete',
            { state: json.data } )

      // 결제 성공 비즈니스 로직을 구현하세요.
    }
    confirm();
  }, []);
    return (
        <>
            <div className="mypage-wrap">
                <div className="inner">
                    <div className="complete">
                        <FaRegGrinSquint color="#0096C0" size={70} />
                        <div className="space">
                            결제가 진행중입니다.
                            <div className="box">
                                <div>
                                    결제에 시간이 소요될 수 있습니다. 화면을 끄지마시고 잠시만 기다려주세요!
                                </div>
                            </div>
                        </div>
                    </ div>
                </div>
            </div>
        </>
    );
};

export default ProcessPay;

