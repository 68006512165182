import React, { useState, useCallback, useEffect } from 'react';
import Header from '../../Components/Header';
import Modal from 'react-bootstrap/Modal';
import MobileDetailMenu from "../../Components/MobileDetailMenu";
import { Link } from "react-router-dom";
import { addComma } from "../../Pages/config.js"
import { FaRegCheckCircle } from "react-icons/fa";
import { BrowserView, MobileView } from 'react-device-detect'
import { RoomName, RankList, RankPrice, roomImgDict, RoomRank, RankColor, RoomExplain, BankCode } from "../../Pages/config.js"
import Select from 'react-select';
import { useNavigate, useLocation } from 'react-router-dom';


const CompleteNiceAPI = () => {
    console.log(window.location.href)
    const navigate = useNavigate();
    console.log(sessionStorage.getItem('branch'))
    console.log(JSON.parse(sessionStorage.getItem('branchList')))
    const [auth, setAuth] = useState(null);
    const [id, setID] = useState("")
    const [pw, setPW] = useState("")
    if (sessionStorage.getItem('branchList')!=null){
        window.location.href = "/admin/book"
    }

//    const [show, setShow] = useState(true);
//    const [token, setToken] = useState(null);
//    const [tab, setTab] = useState("email");
//    const [open, setOpen] = useState(false);
//    const [enc, setEnc] = useState(null);
//    const [integrity, setIntegrity] = useState(null);
//    const [time, setTime] = useState(-100)

//    const [name, setName] = useState("")
//    const [code, setCode] = useState("")
//    const [history, setHistory] = useState([])
//    const [getData, setGetData] = useState(false)
//    const [refund, setRefund] = useState(false)
//    const [expireTime, setExpireTime] = useState(new Date())
//    const [bankCode, setBankCode] = useState("")
//    const [accNum, setAccNum] = useState("")



//    const handleClose = () => setShow(false);
//    const handleShow = () => setShow(true);

    {/*const handleTab = (e) => {
        setTab(e.target.id)
        setOpen(false)
        setID("")
    }*/}

    const doLogin = (e) => {
        console.log({
                    "loginId": id,
                    "password": pw
                    })
        fetch(process.env.REACT_APP_serviceUrl + "/admin/login", {
                credentials: 'include',
                method: "POST",
                headers: {
                      "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                    "loginId": id,
                    "password": pw
                    }
                )
            }).then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.status=="success") {
                    setAuth(json.data)
                    sessionStorage.setItem('branchList', JSON.stringify(json.data.branchAuthList))
                    sessionStorage.setItem('branch', json.data.branchAuthList[0])
                    navigate( '/admin/book', { state: {branchList: json.data.branchAuthList} } )
                } else {
                    window.alert(json.message)
                }

            })
    }


    return (
        <>
            <div className="mypage-wrap">
                <div className="inner">
                    <BrowserView>
                        <div className={"line-rec up on"} id="email" > 관리자 페이지 </div>
                    </BrowserView>
                    <MobileView>
                        <div className={"line-rec up on"} id="email"> 관리자 페이지 </div>
                    </MobileView>

                    <hr className="line" />
                    <div className="form-box">
                        <div className="form-group">
                            <label htmlFor="name" className="label">아이디</label>
                            <input className="form-control" type="text" id="name" placeholder="아이디를 입력해주세요." value={id} onChange={(e)=>{setID(e.target.value)}} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="tel2" className="label">비밀번호</label>
                            <div className="form-with-btn">
                                <input className="form-control" type="text" id="tel" value={pw}
                            onChange={(e)=>{setPW(e.target.value)}} placeholder="비밀번호를 입력해 주세요." />
                            </div>
                        </div>
                         <div className="btn-wrap">
                            <button className="btn btn-black" onClick={doLogin}>조회하기</button>
                         </div>
                    </div>

                </div>
            </div>
            {/*<MobileDetailMenu />
            <Modal className="modal-green" show={show} onHide={handleClose} centered animation={false} backdrop="static">
                <button className="btn-modal-close" onClick={handleClose}><i className="ico ico-modal-close-white" /></button>
                <div className="content">
                    <div className="title">회원 가입을 환영 합니다!</div>
                    <div className="desc">회원 가입 완료</div>
                    <div className="sign-in-completed">
                        <i className="ico ico-completed"/>
                        <div className="txt">{parsed==null? parsed: ''}</div>
                    </div>
                    <div className="btn-wrap">
                        <Link className="link" to='/'><button className="btn btn-black btn-lg" onClick={handleClose}>확인</button></Link>
                    </div>
                </div>
            </Modal>*/}
        </>
    );
};

export default CompleteNiceAPI;
