const companyExplain = {
    "코지마" : "COZYMA는 한국에서 1945년 설립된 복정제형㈜의 브랜드로서, 국내 최초로 3D/4D 기술을 도입한 것으로 유명합니다.",
    "제스파" : "Zespa는 한국에서 1993설립된 건강/미용분야 전문 브랜드로서, 광고등의 홍보가 거의 없음에도 내구성이 좋아 구매율이 높은 것으로 유명합니다.",
    "바디프랜드" : "바디프랜드는 한국에서 2007년 설립된 전문 브랜드로서, 한국내 인지도가 가장 높으며, 편한 작동법과 다양한 기능을 갖춘것으로 유명합니다.",
    "오씸" : "OSIM은 싱가포르에서 1980년에 설립된 40년 이상의 헬스케어 전문 브랜드로서, 사람이 만져주는 것과 같은 안마를 구현하는 것으로 유명합니다.",
    "파나소닉" : "파나소닉은 일본의 최고급 안마의자 브랜드로서, 다양한 특허기술을 바탕으로 체형에 맞는 맞춤형 안마를 구현하는 것으로 유명합니다."
}
export const chairExplain = {
    "파나소닉-EP-MAF1" : {
            "name":"파나소닉 \"EP-MAF1\"",
            "desc" : companyExplain["파나소닉"],
            "strength" : 4.0,
            "size" : 3,
            "special" : "발바닥, 종아리 / 온열가능 / 4D체형측정",
            "etc" : [
               "마사지 롤러만 온열이 되어 핫스톤 안마 재현",
               "발바닥 롤러가 크고 시원함",
               "종아리부분 에어가 쎈편이라 근육이 잘풀림",
               "전반적으로 타이마사지 느낌을 받음"
            ],
            "mode": [
                "*전신마사지", "결림해소", "피로회복", "원기회복", "간단모드(8분)","*수동마사지"
            ],
            "explain": [
                "전원버튼 눌러서 시작",
                "상하좌우로 이동후 OK 버튼 클릭",
                "다리각도 위아래로 조정 ",
                "등받이각도 위아래로 조정 ",
                "전원버튼 눌러서 종료 (사용후 꼭 전원을 꺼주세요. 또한 의자 원위치는 수동이어서 6번을 길게 눌러야만 됩니다.)"
            ],
            "score": {
                "Gwanghwamun" : 0, "Yeouido" : 1,
                "Rest" : 0, "Massage" : 5,
                "Tall": 1, "Small": 1,
                "Neck": 5, "Back": 4, "Hip" : 3, "Thigh": 0, "Calf": 4, "Foot": 5,
                "Thai": 5, "Vietnam":0,
                "1": 0, "2": 3, "3": 5
            }
        },
    "오씸-유러브" : {
            "name":"오씸 \"유러브2\"",
            "desc" : companyExplain["오씸"],
            "strength" : 3.5,
            "size" : 4,
            "special" : "목,어깨 / 온열가능 / 4D체형측정",
            "etc" : [
               "마사지 롤러만 온열이 되어 핫스톤 안마 재현",
               "목과 어깨 안마가 손으로 주무르는 느낌",
               "허벅지 뒷부분까지 마사지",
               "종아리는 꽉 잡아주고 발은 진동식으로 안마"
            ],
            "mode": [
                "*전신마사지", "스포츠", "스트레칭", "뷰티", "수면", "목과 어깨", "머리와 목", "요추와 힙", "힙과 허벅지", "*수동마사지", "*시그니처 마사지"
            ],
            "explain": [
                "전원버튼 눌러서 시작",
                "메뉴클릭시 선택가능한 옵션 확인 가능",
                "상하좌우로 이동후 OK 버튼 클릭 (*** 모드 선택시 OK 버튼을 길게 눌러야만 의자가 조정됩니다. ***)",
                "다리각도 위아래로 조정 ",
                "등받이각도 위아래로 조정 ",
                "컴포트/무중력 모드로 자세 변경 가능",
                "길게 누를시 안마의자 자리가 원위치",
                "전원버튼 눌러서 종료 (사용후 꼭 전원을 꺼주세요. 또한 의자 원위치는 수동이어서 6번을 길게 눌러야만 됩니다.)"
            ],
            "score": {
                "Gwanghwamun" : 1, "Yeouido" : 1,
                "Rest" : 0, "Massage" : 5,
                "Tall": 1, "Small": 1,
                "Neck": 5, "Back": 5, "Hip" : 3, "Thigh": 0, "Calf": 2, "Foot": 5,
                "Thai": 0, "Vietnam":5,
                "1": 4, "2": 4, "3": 2
            }

        },
    "바디프랜드-셀레네2" : {
            "name" : "바디프랜드 \"셀레네2\"",
            "desc" : companyExplain["바디프랜드"],
            "strength" : 5,
            "size" : 5,
            "special" : "목,어깨 / 온열가능",
            "etc" : [
               "롤러가 큼직해서 자극이커서 근육뭉침이 잘풀림",
               "손 부분도 강하게 압력을 주는편",
               "발을 꽉 잡은 상태로 롤러가 돌아감",
            ],
            "mode": [
                "허리집중", "목어깨집중", "회복모드", "스트레칭", "수면모드", "활력모드", "상체자동", "하체자동", "수험생모드", "힙업모드", "골프모드", "요가모드", "케어모드",
            ],
            "explain": [
                "전원버튼 눌러서 시작",
                "전체메뉴 확인가능",
                "상하좌우로 이동후 OK 버튼 클릭",
                "온열여부 선택",
                "다리각도 위아래로 조정 ",
                "안마해주는 위치 위아래 조정",
                "전신각도 위아래로 조정",
                "우주인 체험기능 - 의자를 완전히 뒤로 눕혀서 편안한 자세로 휴식가능 ",
                "전원버튼 눌러서 종료 (사용후 꼭 전원을 꺼주세요.)"
            ],
            "score": {
                "Gwanghwamun" : 1, "Yeouido" : 1,
                "Rest" : 2, "Massage" : 3,
                "Tall": 1, "Small": 1,
                "Neck": 3, "Back": 3, "Hip" : 4, "Thigh": 0, "Calf": 3, "Foot": 3,
                "Thai": 3, "Vietnam":3,
                "1": 2, "2": 4, "3": 4
            }
        },
    "코지마-카이저" : {
            "name" : "코지마 \"카이저\"",
            "desc" : companyExplain["바디프랜드"],
            "strength" : 3.5,
            "size" : 4,
            "special" : "머리,허리 / 온열가능 / 4D체형측정",
            "etc" : [
               "체형측정을 통한 맞춤형 안마",
               "무릎, 머리 마사지 기능있음",
               "허리부분을 꽉 잡고 풀어줌",
               "발마사지도 롤링방식으로 시원하게 풀어줌",
               "아프지않게 적당한 압으로 풀어줌",
            ],
            "mode": [
                "황실모드", "어깨모드", "척추모드", "무릎모드", "수동모드"
            ],
            "explain": [
                "자리 오른쪽 전원버튼 눌러서 시작",
                "모드 전체 선택가능",
                "자리 오른쪽 전원버튼 눌러서 종료 (사용후 꼭 전원을 꺼주세요.)"
            ],
            "score": {
                "Gwanghwamun" : 1, "Yeouido" : 1,
                "Rest" : 2, "Massage" : 5,
                "Tall": 1, "Small": 1,
                "Neck": 4, "Back": 4, "Hip" : 4, "Thigh": 3, "Calf": 5, "Foot": 4,
                "Thai": 3, "Vietnam":5,
                "1": 2, "2": 5, "3": 2
            }
        },
    "바디프랜드-프레지던트" : {
            "name" : "바디프랜드 \"프레지던트\"",
            "desc" : companyExplain["바디프랜드"],
            "strength" : 4,
            "size" : 3.5,
            "special" : "목,어깨 / 온열가능",
            "etc" : [
               "안마봉의 크기가 커서 지그시 눌러주는 느낌",
               "안마의자의 크기가 커서 전신이 잘 커버됨",
               "허벅지 뒷부분까지 마사지",
               "종아리는 꽉 잡아주고 발은 진동식으로 안마",
               "옆구리 에어로 한쪽씩 미는데 시원함"
            ],
            "mode": [
                "활력모드", "휴식모드", "스트레칭모드", "수면모드"
            ],
            "explain": [
                "전원버튼 눌러서 시작",
                "활력/휴식/스트레칭/수면 선택가능",
                "부위별 안마여부, 안마강도 등 각종 옵션들 선택가능",
                "허리운동 여부",
                "온열여부 선택",
                "전신각도 위아래로 조정",
                "다리각도 위아래로 조정 ",
                "동작 일시정지 여부 등 선택",
            ],
            "score": {
                "Gwanghwamun" : 0, "Yeouido" : 0,
                "Rest" : 0, "Massage" : 5,
                "Tall": 1, "Small": 1,
                "Neck": 4, "Back": 3, "Hip" : 3, "Thigh": 0, "Calf": 2, "Foot": 2,
                "Thai": 5, "Vietnam":0,
                "1": 0, "2": 2, "3": 5
            }
        },
    "바디프랜드-팬텀" : {
            "name" : "바디프랜드 \"뉴팬텀\"",
            "desc" : companyExplain["바디프랜드"],
            "strength" : 4.5,
            "size" : 5,
            "special" : "어깨,허리 / 온열가능",
            "etc" : [
               "마사지 롤러가 크고 압이 쎈편",
               "발을 꽉 잡은 상태에서 안마해줌",
               "의자가 전반적으로 커서 푹쉬기 좋음",
               "스트레칭 모드로 하면 발잡아서 늘려주는데 고관절과 앞허벅지 시원"
            ],
            "mode": [
                "*자동모드", "활력모드", "스트레칭모드", "수면모드", "상체자동", "하체자동", "*수동모드"
            ],
            "explain": [
                "전원버튼 눌러서 시작",
                "전체메뉴 확인가능",
                "상하좌우로 이동후 OK 버튼 클릭",
                "다리각도 위아래로 조정 ",
                "전신각도 위아래로 조정",
                "안마해주는 위치 위아래 조정",
                "우주인 체험기능 - 의자를 완전히 뒤로 눕혀서 편안한 자세로 휴식가능 ",
                "전원버튼 눌러서 종료 (사용후 꼭 전원을 꺼주세요. 의자를 원위치하시겠습니까? 라는 질문에 \"예\"를 눌러야만 원위치 됩니다.)"
            ],
            "score": {
                "Gwanghwamun" : 1, "Yeouido" : 0,
                "Rest" : 0, "Massage" : 5,
                "Tall": 1, "Small": 1,
                "Neck": 4, "Back": 3, "Hip" : 4, "Thigh": 0, "Calf": 3, "Foot": 3,
                "Thai": 5, "Vietnam":0,
                "1": 0, "2": 5, "3": 5
            }
        },
     "바디프랜드-팬텀2" : {
            "name" : "바디프랜드 \"팬텀2\"",
            "desc" : companyExplain["바디프랜드"],
            "strength" : 4.5,
            "size" : 5,
            "special" : "어깨,허리 / 온열가능",
            "etc" : [
               "마사지 롤러가 크고 압이 쎈편",
               "발을 꽉 잡은 상태에서 안마해줌",
               "의자가 전반적으로 커서 푹쉬기 좋음",
               "스트레칭 모드로 하면 발잡아서 늘려주는데 고관절과 앞허벅지 시원"
            ],
            "mode": [
                "*자동모드", "허리집중", "목/어깨집중", "회복모드", "스트레칭", "수면모드","활력모드","상체자동","하체자동","수험생모드", "힙업모드", "골프모드",  "요가모드",  "케어모드","림프마사지", "하지림프모드", "소화/숙취해소", "*수동모드"
            ],
            "explain": [
                "전원버튼 눌러서 시작",
                "전체메뉴 확인가능",
                "상하좌우로 이동후 OK 버튼 클릭",
                "다리각도 위아래로 조정 ",
                "전신각도 위아래로 조정",
                "안마해주는 위치 위아래 조정",
                "전원버튼 눌러서 종료 (사용후 꼭 전원을 꺼주세요. 의자를 원위치하시겠습니까? 라는 질문에 \"예\"를 눌러야만 원위치 됩니다.)"
            ],
            "score": {
                "Gwanghwamun" : 0, "Yeouido" : 1,
                "Rest" : 0, "Massage" : 5,
                "Tall": 1, "Small": 1,
                "Neck": 4, "Back": 4, "Hip" : 4, "Thigh": 0, "Calf": 4, "Foot": 4,
                "Thai": 5, "Vietnam":0,
                "1": 0, "2": 3, "3": 5
            }
        },
    "바디프랜드-아제라" : {
            "name" : "바디프랜드 \"아제라\"",
            "desc" : companyExplain["바디프랜드"],
            "strength" : 4,
            "size" : 3,
            "special" : "종아리 / 온열가능",
            "etc" : [
               "종아리 근육을 롤러로 가장 쎄게 잘풀어줌 (뭉친근육이 있다면 아플수 있지만 확실하게 풀어줌)",
               "압이 쎈편임",
               "전신을 밸런스있게 훑어줌",
            ],
            "mode": [
                "*자동모드", "회복모드", "스트레칭", "수면모드", "활력모드", "상체자동", "하체자동", "다리집중", "힙업모드", "허리집중", "목어깨집중", "슬로우모드", "저소음모드", "*수동모드"
            ],
            "explain": [
                "전원버튼 눌러서 시작",
                "전체메뉴 확인가능",
                "상하좌우로 이동후 OK 버튼 클릭",
                "안마해주는 위치 위아래 조정",
                "다리각도 위아래로 조정 ",
                "전신각도 위아래로 조정",
                "온열 여부 선택",
                "무중력 여부",
                "전원버튼 눌러서 종료 (사용후 꼭 전원을 꺼주세요. 의자를 원위치하시겠습니까? 라는 질문에 \"예\"를 눌러야만 원위치 됩니다.)"
            ],
            "score": {
                "Gwanghwamun" : 1, "Yeouido" : 1,
                "Rest" : 2, "Massage" : 3,
                "Tall": 1, "Small": 1,
                "Neck": 3, "Back": 3, "Hip" : 4, "Thigh": 0, "Calf": 5, "Foot": 3,
                "Thai": 3, "Vietnam":3,
                "1": 2, "2": 4, "3": 4
            }
        },
    "바디프랜드-엘리자베스" : {
            "name" : "바디프랜드 \"엘리자베스\"",
            "desc" : companyExplain["바디프랜드"],
            "strength" : 4,
            "size" : 3,
            "special" : "종아리 / 온열가능",
            "etc" : [
               "종아리 근육을 롤러로 가장 쎄게 잘풀어줌 (뭉친근육이 있다면 아플수 있지만 확실하게 풀어줌)",
               "압이 쎈편임",
               "전신을 밸런스있게 훑어줌",
            ],
            "mode": [
                "*자동모드", "*수동모드"
            ],
            "explain": [
                "전원버튼 눌러서 시작",
                "자동모드/수동모드 선택",
                "다리각도 위아래로 조정 ",
                "전신각도 위아래로 조정",
                "마사지 옵션 선택",
                "전원버튼 눌러서 종료 (사용후 꼭 전원을 꺼주세요. 의자를 원위치하시겠습니까? 라는 질문에 \"예\"를 눌러야만 원위치 됩니다.)"
            ],
            "score": {
                "Gwanghwamun" : 0, "Yeouido" : 1,
                "Rest" : 5, "Massage" : 2,
                "Tall": 1, "Small": 1,
                "Neck": 3, "Back": 3, "Hip" : 3, "Thigh": 0, "Calf": 3, "Foot": 3,
                "Thai": 0, "Vietnam":3,
                "1": 3, "2": 5, "3": 0
            }
        },
    "제스파-루넉스" : {
            "name" : "제스파 \"루넉스\"",
            "desc" : companyExplain["제스파"],
            "strength" : 3.5,
            "size" : 3,
            "special" : "허리 / 온열가능",
            "etc" : [
               "마사지 롤러가 전반적으로 뾰족하고 압이 쎈 느낌",
               "전신을 밸런스있게 훑어줌",
               "허벅지 뒷부분까지 마사지",
               "종아리는 꽉 잡아주고 발은 진동식으로 안마",
               "의자가 엄청 큰편은 아니지만 전신은 충분히 커버됨"
            ],
            "mode": [
                "*전신관리", "*하반신관리", "*자동모드", "목중심", "수면", "스트레칭", "*수동모드"
            ],
            "explain": [
                "전원버튼 눌러서 시작",
                "자동모드 중 전신관리 모드",
                "자동모드 중 하반신관리 모드",
                "자동모드 중 목중심/수면/스트레칭 (클릭시마다 변경됨)",
                "수동모드 주무름/두드림, 공기압, 마사지강도 선택해서 진행",
                "온열모드 선택해서 진행",
                "전원버튼 눌러서 종료 (사용후 꼭 전원을 꺼주세요.)"
            ],
            "score": {
                "Gwanghwamun" : 1, "Yeouido" : 0,
                "Rest" : 3, "Massage" : 3,
                "Tall": 1, "Small": 1,
                "Neck": 3, "Back": 3, "Hip" : 3, "Thigh": 0, "Calf": 3, "Foot": 3,
                "Thai": 5, "Vietnam":0,
                "1": 3, "2": 3, "3": 0
            }
        },
    "코지마-오브제" : {
            "name" : "코지마 \"오브제\"",
            "desc" : companyExplain["코지마"],
            "strength" : 1.5,
            "size" : 4,
            "special" : "목,어깨 / 온열가능",
            "etc" : [
               "안마의자로서의 기능보단 휴식에 초점을 맞춘느낌",
               "압이 세지 않아서 편하게 쉬기에 좋음",
               "의자는 크고 포근한편",
               "종아리 안마는 롤러식",
            ],
            "mode": [
                "*자동모드", "아침모드", "저녁모드", "스트레칭모드", "수면모드", "체험모드", "부위집중모드", "사용자맞춤모드"
            ],
            "explain": [
                "전원버튼 길게 눌러서 시작",
                "메뉴 클릭후 모드 선택",
                "의자 위치조정 (의자 각도, 다리 각도 조정)",
                "전원버튼 길게 눌러서 종료 (사용후 꼭 전원을 꺼주세요.)"
            ],
            "score": {
                "Gwanghwamun" : 0, "Yeouido" : 1,
                "Rest" : 3, "Massage" : 3,
                "Tall": 1, "Small": 1,
                "Neck": 3, "Back": 3, "Hip" : 3, "Thigh": 0, "Calf": 3, "Foot": 3,
                "Thai": 5, "Vietnam":0,
                "1": 3, "2": 3, "3": 0
            }
        },
    "코지마-코지체어" : {
            "name" : "코지마 \"코지체어\"",
            "desc" : companyExplain["코지마"],
            "strength" : 1,
            "size" : 2,
            "special" : "목,어깨 / 온열가능",
            "etc" : [
               "안마의자로서의 기능보단 휴식에 초점을 맞춘느낌",
               "압이 세지 않아서 편하게 쉬기에 좋음",
               "의자가 조금 작은편이라 180cm 이하에 추천",
               "종아리 안마는 롤러식",
            ],
            "mode": [
                "자동모드", "수동모드"
            ],
            "explain": [
                "전원버튼 길게 눌러서 시작",
                "자동모드/수동모드 선택 (양쪽에서 옵션 선택가능, 자동모드는 누를때마다 바뀌며 총 3가지버전)",
                "의자 위치조정",
                "무중력 모드 선택가능",
                "전원버튼 길게 눌러서 종료 (사용후 꼭 전원을 꺼주세요.)"
            ],
            "score": {
                "Gwanghwamun" : 1, "Yeouido" : 1,
                "Rest" : 5, "Massage" : 0,
                "Tall": 0, "Small": 1,
                "Neck": 2, "Back": 2, "Hip" : 0, "Thigh": 0, "Calf": 2, "Foot": 0,
                "Thai": 0, "Vietnam":2,
                "1": 5, "2": 0, "3": 0
            }
        }
}


