import Header from "../Components/Header";
import MobileDetailMenu from "../Components/MobileDetailMenu";
import React, { useState, useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect'
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RoomName, RankList, RankPrice, roomImgDict, RoomRank, RankColor, RoomExplain, branchWIFI, branchETC, branchAddress } from "./config.js"
import { Bs1SquareFill, Bs2SquareFill, Bs3SquareFill, Bs4SquareFill, Bs5SquareFill, Bs6SquareFill, Bs7SquareFill, Bs8SquareFill, Bs9SquareFill } from "react-icons/bs";
import { FaSquare } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { TbStar, TbStarHalf } from "react-icons/tb";


const ExplainAddress = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [roomNum, setRoomNum] = useState("");
    const [roomImg, setRoomImg] = useState("");
    const [branch, setBranch] = useState(searchParams.get('branch'));
    console.log(branch)


    const [key, setKey] = useState("");


    useEffect(() => {
        console.log(searchParams.get('room'))
        console.log(roomImgDict[branch]["Room "+searchParams.get('room')])
        setRoomNum(searchParams.get('room'))
        setBranch(searchParams.get('branch'))
        setKey(searchParams.get('key'))
    }, [])

   useEffect(() => {
        setRoomImg(roomImgDict[branch]["Room "+searchParams.get('room')])
    }, [branch, roomNum])

    console.log("searchParams")
    console.log(searchParams)
    console.log(searchParams.get('room'))
    console.log(typeof(searchParams.get(searchParams.get('room'))))



    const tabList = ["출입","안마의자 작동법"]
    const [tab, setTab] = useState(tabList[0]);
    const handleTab = (e) => {
        setTab(e.target.id)
    }
    const handleRoom = (e) => {
        setRoomNum(e.target.id)
        setRoomImg(roomImgDict[branch][e.target.id])
    }

    const openDoorTemp = (e) => {
        window.alert("현재 출입문 도어락이 수리중인 관계로 문이 열려있습니다.")
    }

    const openDoor = (e) => {
        console.log(e.target.value)
        fetch(process.env.REACT_APP_serviceUrl + "/door/unlock", {
                credentials: 'include',
                method: "POST",
                headers: {
                      "Content-Type": "application/json",
                },
                body: JSON.stringify(
                    {
                    "branch": branch,
                    "doorlockType": e.target.value,
                    "key": key,
                    }
                )
                }).then(res => res.json())
                .then(json => {
                    if (json.status=="success") {
                        window.alert("문을 열었습니다. (실제 도어락 작동시까지 조금 시간이 걸릴수 있습니다.)")
                    } else {
                        window.alert(json.message)
                    }
                })
    }
    return (
        <>
            <div className="intro-wrap">
                <div className="visual empty">
                </div>
                <BrowserView>
                    {tabList.map((t, i)=>
                        <div className={tab==t?"line-rec up on":"line-rec up"} id={t} onClick={handleTab}> {t} </div>
                    )}
                </BrowserView>

                <MobileView>
                    {tabList.map((t, i)=>
                        <div className={tab==t?"line-rec-mobile up on":"line-rec-mobile up"} id={t} onClick={handleTab}> {t} </div>
                    )}
                </MobileView>
                <hr className="line" />
                {tab==tabList[0]?
                <div className="inner">
                    <div className="img-row">
                        <div className="txt-box right">
                            <div className="txt-lg">입장하기</div>
                        </div>
                    </div>
                    <button className="btn btn-black-half blue" value="front" onClick={openDoor}>출입문열기</button>
                    {roomNum!=""?
                        <button className="btn btn-black-half green" value="room" onClick={openDoor}>{roomNum+"번방열기"}</button>
                        :
                        <button className="btn btn-black-half green" value="room" onClick={openDoor}>개별방열기</button>
                     }
                     <div className="img-row">
                        <div className="txt-box right">
                            <div className="txt-lg">오시는 길</div>
                        </div>
                     </div>
                     <div className="txt-box right">
                        <div className="txt-md">{branchAddress[branch][0]}</div>
                        <div className="desc">{branchAddress[branch][1]}</div>
                    </div>

                    <div className="img-row">
                        <div className="img-lg">
                            <img src={process.env.PUBLIC_URL + '/img/store/'+branch+'/가게전면.webp'} alt="헤드텍스트가 들어갑니다"/>
                        </div>
                        <div className="img-lg">
                            <img src={process.env.PUBLIC_URL + '/img/store/'+branch+'/가게측면.webp'} alt="헤드텍스트가 들어갑니다"/>
                        </div>
                    </div>


                    <div className="img-row">
                        <div className="txt-box right">
                            <div className="txt-lg">와이파이</div>
                        </div>
                    </div>
                    <div className="txt-box right">
                        <div className="txt-md">{ branchWIFI[branch][0] }</div>
                        <div className="txt-md">{ branchWIFI[branch][1] }</div>
                    </div>

                    <div className="img-row">
                        <div className="txt-box right">
                            <div className="txt-lg">주차</div>
                        </div>
                    </div>
                    <div className="txt-box right">
                        <div className="txt-md">{ branchETC[branch][0] }</div>
                        <div className="desc">{ branchETC[branch][1] }</div>
                    </div>

                    <div className="img-row">
                        <div className="txt-box right">
                            <div className="txt-lg">유의사항</div>
                        </div>
                     </div>
                     <div className="txt-box right">
                        <div className="txt-md">1. 음식물 반입금지</div>
                        <div className="desc">음식물 등으로 인해 안마의자 오염/고장시 배상책임을 물을 수 있으니 주의해주세요.</div>
                    </div>
                    <div className="txt-box">
                        <div className="txt-md">2. 예약시간 준수</div>
                        <div className="desc">예약시간과 다르게 이용시 초과 사용시간에 대해서 비용이 청구될 수 있으니 주의해주세요.</div>
                    </div>
                    <div className="txt-box">
                        <div className="txt-md">3. 실내에서 정숙 유지</div>
                        <div className="desc">수면을 취하시려는 다른 분들을 위해 소음은 최대한 조심해 주세요.</div>
                    </div>
                    <div className="txt-box">
                        <div className="txt-md">4. CCTV 작동중</div>
                        <div className="desc">여러가지 목적을 위해서 CCTV가 작동중이예요.</div>
                    </div>
                    <div className="txt-box">
                        <div className="txt-md">5. 모든 시설 및 물건 도난 금지</div>
                        <div className="desc">각 방안/복도에 구비된 물건들은 공간을 더 쾌적하게 하기위한 물건들입니다. 가지고 가지말아주세요.</div>
                    </div>

                </div>
                    :
                <div className="inner">
                        {/*<BrowserView>
                            <div className="time">
                                <div className={room=="Room 1"?"time-rec on":"time-rec"} id="Room 1" onClick={handleRoom}>ROOM 1</div>
                                <div className={room=="Room 2"?"time-rec on":"time-rec"} id="Room 2" onClick={handleRoom}>ROOM 2</div>
                                <div className={room=="Room 3"?"time-rec on":"time-rec"} id="Room 3" onClick={handleRoom}>ROOM 3</div>
                                <div className={room=="Room 4"?"time-rec on":"time-rec"} id="Room 4" onClick={handleRoom}>ROOM 4</div>
                                <div className={room=="Room 5"?"time-rec on":"time-rec"} id="Room 5" onClick={handleRoom}>ROOM 5</div>
                                <div className={room=="Room 6"?"time-rec on":"time-rec"} id="Room 6" onClick={handleRoom}>ROOM 6</div>
                                <div className={room=="Room 7"?"time-rec on":"time-rec"} id="Room 7" onClick={handleRoom}>ROOM 7</div>
                                <div className={room=="Room 8"?"time-rec on":"time-rec"} id="Room 8" onClick={handleRoom}>ROOM 8</div>
                                <div className={room=="Room 9"?"time-rec on":"time-rec"} id="Room 9" onClick={handleRoom}>ROOM 9</div>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div className="date">
                                <IoIosArrowBack style={{marginRight : 20}} />
                                    ROOM 1
                                <IoIosArrowForward style={{marginLeft : 20}} />
                            </div>
                        </MobileView>*/}
                    <div className="img-row">
                        <div className="txt-box right">
                            <div className="txt-lg">안마의자 소개</div>
                        </div>
                    </div>
                    <div>
                        { roomImg==""?
                        <div/>
                        :

                        <>
                            <BrowserView>
                                <div className="img-row">
                                    <div className="img-col width-50">
                                        <div className="img-sm">
                                            <img src={process.env.PUBLIC_URL + "/img/chair/" + branch + "/"+ roomImg} alt="각 안마의자 이미지"/>
                                        </div>
                                    </div>
                                    <div className="img-col mobile">
                                        <div className="txt-box">
                                            <div className="form-group">
                                                <label htmlFor="id" className={"rec-label "+RoomRank[branch][roomNum]}>{RoomRank[branch][roomNum]} </label>
                                            </div>

                                            <div className="txt-md">{RoomName[branch][roomNum]}</div>
                                            <div className="val">
                                                <div className="desc">
                                                    마사지 세기 :
                                                    {([...Array(parseInt(Math.floor(RoomExplain[branch][roomNum]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}
                                                    {([...Array(parseInt(Math.ceil(RoomExplain[branch][roomNum]["strength"]%1)))]).map((n, i) => <TbStarHalf color="FFB137" />)}
                                                    {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[roomNum]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                                </div>
                                                <div className="desc">
                                                    안마의자 크기 :
                                                    {([...Array(parseInt(Math.floor(RoomExplain[branch][roomNum]["size"])))]).map((n, i) => <TbStar color="FFB137" />)}
                                                    {([...Array(parseInt(Math.ceil(RoomExplain[branch][roomNum]["size"]%1)))]).map((n, i) => <TbStarHalf color="FFB137" />)}
                                                    {/*([...Array(parseInt(5-Math.ceil(RoomExplain[branch][roomNum]["size"])))]).map((n, i) => <TbStar color="FFB137" />)*/}
                                                </div>
                                            </div>
                                            {(RoomExplain[branch][roomNum]["etc"]).map((item, idx) => {
                                                return <div className="desc">- {item}</div>
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </BrowserView>
                            <MobileView>
                                <div className="img-row mobile">
                                    <div className="img-sm mobile">
                                        <img src={process.env.PUBLIC_URL + "/img/chair/" + branch + "/"+ roomImg} alt="각 안마의자 이미지"/>
                                    </div>
                                </div>
                                <div className="img-row mobile">
                                    <div className="txt-box mobile">
                                        <div className="form-group mobile">
                                            <label htmlFor="id" className={"rec-label "+RoomRank[branch][roomNum]}>{RoomRank[branch][roomNum]} </label>
                                        </div>

                                        <div className="txt-md">{RoomName[branch][roomNum]}</div>
                                        <div className="val">
                                            <div className="desc">
                                                마사지 세기 :
                                                {([...Array(parseInt(Math.floor(RoomExplain[branch][roomNum]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}
                                                {([...Array(parseInt(Math.ceil(RoomExplain[branch][roomNum]["strength"]%1)))]).map((n, i) => <TbStarHalf color="FFB137" />)}
                                                {/*{([...Array(parseInt(5-Math.ceil(RoomExplain[roomNum]["strength"])))]).map((n, i) => <TbStar color="FFB137" />)}*/}
                                            </div>
                                            <div className="desc">
                                                안마의자 크기 :
                                                {([...Array(parseInt(Math.floor(RoomExplain[branch][roomNum]["size"])))]).map((n, i) => <TbStar color="FFB137" />)}
                                                {/*{([...Array(parseInt(Math.ceil(RoomExplain[roomNum]["size"]%1)))]).map((n, i) => <TbStarHalf color="FFB137" />)}*/}
                                                {([...Array(parseInt(5-Math.ceil(RoomExplain[branch][roomNum]["size"])))]).map((n, i) => <TbStar color="FFB137" />)}
                                            </div>
                                        </div>
                                        {(RoomExplain[branch][roomNum]["etc"]).map((item, idx) => {
                                            return <div className="desc">- {item}</div>
                                        })}

                                    </div>
                                </div>
                            </MobileView>
                        </>
                        }
                    </div>
                    <div className="img-row">
                        <div className="txt-box right">
                            <div className="txt-lg">안마의자 사용법</div>
                        </div>
                    </div>
                    <BrowserView>
                        <div className="img-row">
                            { roomImg==""?
                            <div />
                            :
                            <div className="img-sm">
                                <img src={process.env.PUBLIC_URL + "/img/chair/" + branch + "/"+ roomImg.replace(".webp","_rc.webp")} alt="각 안마의자 이미지"/>
                            </div>
                            }
                            <div className="txt-box">
                                <div className="time">
                                    <div className="desc-sub">** 안마의자 커버(목 뒤쪽)는 벗겨내지 않을시 목/어깨 안마를 거의 느낄수 없으니 꼭 뒤로 젖히신후 사용해주세요! **</div><br/>
                                    <FaSquare color={"#FF0000"} size={27} />
                                    <div className="desc-sub">{RoomExplain[branch][roomNum]["explain"][0]}</div><br/>
                                    {RoomExplain[branch][roomNum]["explain"].slice(1,-1).map((exp, idx)=>(
                                        <>
                                            <div className="desc-sub rec" >{idx+1}</div>
                                            <div className="desc-sub">{exp}</div><br/>
                                        </>
                                    ))}
                                    <FaSquare color={"#FF0000"} size={27} />
                                    <div className="desc-sub">{RoomExplain[branch][roomNum]["explain"][RoomExplain[branch][roomNum]["explain"].length-1]}</div><br/>
                                </div>
                            </div>
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className="img-row mobile">
                            { roomImg==""?
                            <div />
                            :
                            <div className="img-sm mobile">
                                <img src={process.env.PUBLIC_URL + "/img/chair/" + branch + "/"+ roomImg.replace(".webp","_rc.webp")} alt="각 안마의자 이미지"/>
                            </div>
                            }
                            <div className="txt-box">
                                <div className="time">
                                    <FaSquare color={"#FF0000"} size={27} />
                                    <div className="desc-sub">{RoomExplain[branch][roomNum]["explain"][0]}</div><br/>
                                    {RoomExplain[branch][roomNum]["explain"].slice(1,-1).map((exp, idx)=>(
                                        <>
                                            <div className="desc-sub rec" >{idx+1}</div>
                                            <div className="desc-sub">{exp}</div><br/>
                                        </>
                                    ))}
                                    <FaSquare color={"#FF0000"} size={27} />
                                    <div className="desc-sub">{RoomExplain[branch][roomNum]["explain"][RoomExplain[branch][roomNum]["explain"].length-1]}</div><br/>
                                </div>
                            </div>
                        </div>
                    </MobileView>
                    <>
                        <div className="img-row">
                            <div className="txt-box right">
                                <div className="txt-lg">편의시설</div>
                            </div>
                        </div>

                        <div className="img-col">
                            <div className="img-row">
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + "/img/facility/각방조명.webp"} alt="각 안마의자 이미지"/>
                                    <div className="txt-box">
                                        <div className="desc right">- 수면에 적합하도록 각 방에 조명을 개별로 설치하였습니다. </div>
                                    </div>
                                </div>
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + "/img/facility/안대.webp"} alt="각 안마의자 이미지"/>
                                    <div className="txt-box">
                                        <div className="desc right">- 수면시 사용하실 수 있는 안대를 각 방에 준비했습니다. </div>
                                    </div>
                                </div>
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + "/img/facility/공기청정기.webp"} alt="각 안마의자 이미지"/>
                                    <div className="txt-box">
                                        <div className="desc right">- 공기청정기를 통해 맑고 깨끗한 공기를 제공합니다.</div>
                                    </div>
                                </div>

                            </div>
                            <div className="img-row">
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + "/img/facility/산소발생기.webp"} alt="각 안마의자 이미지"/>
                                    <div className="txt-box">
                                        <div className="desc right">- 산소발생기와 디퓨저로 산소가득 쾌적한 공기를 제공합니다.</div>
                                    </div>
                                </div>
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + "/img/facility/충전기.webp"} alt="각 안마의자 이미지"/>
                                    <div className="txt-box">
                                        <div className="desc right">- 편의를 위해 각 방에 충전기가 구비되어있습니다.</div>
                                    </div>
                                </div>
                                <div className="img-sm width">
                                    <img src={process.env.PUBLIC_URL + "/img/facility/소화기.webp"} alt="각 안마의자 이미지"/>
                                    <div className="txt-box">
                                        <div className="desc right">- 안전을 위해 각 방과 복도에 소화기가 구비되어 있습니다.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
                }
            </div>
            {/*<MobileDetailMenu />  */}
        </>
    );
};

export default ExplainAddress;
